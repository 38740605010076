import "./styles.css";

const Footer = () => {
  return (
    <footer className="app-footer">
      <div>
        <div></div>
        <div></div>
      </div>
      <hr />
      <span className="copyright">
        <h3>Copyright Nixon Yang {"🍀"} © 2022 all rights reserved</h3>
      </span>
    </footer>
  );
};

export default Footer;

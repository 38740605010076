import { MutableRefObject, RefObject } from "react";
import { motion } from "framer-motion";
import images from "../../../assets/images";
import { NavLink } from "react-router-dom";
interface Props {
  projectRef: MutableRefObject<HTMLElement | undefined>;
}

const Project = ({ projectRef }: Props) => (
  <section
    className="project-container"
    ref={projectRef as RefObject<HTMLElement>}
  >
    <div className="project-body">
      <motion.h2 className="project-title">Project</motion.h2>
      <NavLink to="project">
        <div
          className="project-poster"
          style={{ backgroundImage: `url(${images.project})` }}
        >
          <h2 className="project-discover">Discover More</h2>
        </div>
      </NavLink>
    </div>
  </section>
);

export default Project;

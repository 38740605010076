import { MutableRefObject, RefObject } from "react";
import ContactForm from "../../../components/Contact";

interface Props {
  contactRef: MutableRefObject<HTMLElement | undefined>;
}

const Contact = ({ contactRef }: Props) => (
  <section
    className="contact-container"
    ref={contactRef as RefObject<HTMLElement>}
  >
    <ContactForm />
  </section>
);

export default Contact;

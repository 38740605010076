import { useEffect, useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import { Contact } from "../../../API";
import { contactAPI } from "../../../utils";

const Dashboard = () => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  useEffect(() => {
    if (contacts.length < 1) fetchData();
  }, [contacts.length]);
  const fetchData = async () => {
    const { items } = await contactAPI.fetch();
    setContacts(items);
    console.log(items);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDelete = async (id: string) => {
    const response = await contactAPI.delete(id);
    console.log(response);
    fetchData();
  };
  return (
    <section>
      <h2>Dashboard</h2>
      <div>
        <h3>Contacts</h3>
        <Button primary onClick={fetchData}>
          Refresh
        </Button>
        <ul className="contact-message-list">
          {contacts.length < 1 && <h2>There is no messages</h2>}
          {contacts.map((contact: Contact, i) => (
            <li className="contact-message-item" key={`contact-msg-item-${i}`}>
              <div>
                <Icon name="envelope open" /> Subject: {contact.name}
              </div>
              <div>From: {contact.email}</div>
              <div>Date: {new Date(contact.createdAt).toString()}</div>
              <p>{contact.message}</p>
              <Button.Group>
                <a href={`mailto:${contact.email}`}>
                  <Button color="olive">Reply</Button>
                </a>
                {/* <Button onClick={() => handleDelete(contact.id)}>Delete</Button> */}
              </Button.Group>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Dashboard;

import { useEffect, useState } from "react";
import { Dimmer, Icon, Loader } from "semantic-ui-react";
import { Project } from "../../../API";
import { build_string, fileAPI, FILE_TYPE, IMAGE_TAG } from "../../../utils";

interface Props {
  project: Project;
  idx: number;
  max: number;
  setOpen: (value: boolean) => void;
  handleNext: () => void;
  handlePrevious: () => void;
}
interface MetaData {
  uri: string;
  type: string;
}
const Details = ({
  project,
  idx,
  max,
  setOpen,
  handleNext,
  handlePrevious,
}: Props) => {
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState<Array<MetaData>>([]);
  useEffect(() => {
    const fetchData = async () => {
      if (project.tags.includes("comic")) {
        const data = await Promise.all(
          project.files.map(async (uri) => {
            if (uri)
              return {
                uri: await fileAPI.get(
                  build_string([FILE_TYPE.image, IMAGE_TAG.image4K, uri])
                ),
                type: "image",
              };
            return {
              uri: "NA",
              type: "NA",
            };
          })
        );
        setFiles(data);
      } else {
        const { items } = await fileAPI.fetch({
          filter: {
            projects: {
              contains: project.id,
            },
          },
        });
        const data = await Promise.all(
          items.map(async (item) => {
            let uri: string, type: string;
            if (item.type.includes("image")) {
              type = "image";
              uri = build_string([
                FILE_TYPE.image,
                IMAGE_TAG.imageHD,
                item.uri,
              ]);
            } else if (item.type.includes("video")) {
              type = "video";
              uri = build_string([FILE_TYPE.video, item.uri]);
            } else {
              type = "unknown";
              uri = item.uri;
            }
            return { uri: await fileAPI.get(uri), type };
          })
        );
        setFiles(data);
      }
      setLoading(false);
    };
    fetchData();
    console.log(files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);
  return (
    <Dimmer active>
      <div className="artwork-toolbar"
          style={{top: "5rem"}}>
        <div
          className="artwork-backward"
          onClick={() => {
            setOpen(false);
          }}
        >
          <Icon name="reply" />
        </div>
      </div>
      <div className="project-details-btn project-previous">
        <Icon
          name="chevron left"
          disabled={idx < 1}
          onClick={() => {
            handlePrevious();
            setLoading(true);
          }}
        />
      </div>
      <div className="project-details-btn project-next">
        <Icon
          name="chevron right"
          disabled={idx + 1 > max - 1}
          onClick={() => {
            handleNext();
            setLoading(true);
          }}
        />
      </div>
      {loading && <Loader />}
      {!loading && (
        <>
          <div className="project-details">
            <div
              className={`project-details-list ${
                files.length < 2 && "project-justify-center"
              }`}
            >
              {files.map(({ uri, type }, idx) => {
                if (type === "image")
                  return (
                    <img
                      src={uri}
                      alt="broken link"
                      key={`file-item-${idx}`}
                      style={{ width: "100%" }}
                      loading="lazy"
                    />
                  );
                else if (type === "video")
                  return (
                    <video autoPlay width="100%" key={`file-item-${idx}`}>
                      <source src={uri} type="video/mp4" />
                    </video>
                  );
                return <div>{uri}</div>;
              })}
            </div>
          </div>
        </>
      )}
    </Dimmer>
  );
};

export default Details;

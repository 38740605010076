import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Outlet } from "react-router-dom";
import images from "../../assets/images";
import Navigation, { NavItem } from "../../components/Navigation";
import "./styles.css";

const Admin = () => {
  return (
    <Authenticator className="admin-container" loginMechanisms={["email"]} hideSignUp>
      {({ signOut, user }) => (
        <>
          <header className="admin-header">
            <img src={images.logo} alt="logo" />
            <h1>Schwifty</h1>
          </header>
          <Navigation>
            <NavItem to="/schwifty">Dashboard</NavItem>
            <NavItem to="projects">Projects</NavItem>
            <NavItem to="tags">Tags</NavItem>
            <NavItem to="images">Images</NavItem>
            <NavItem to="models">Models</NavItem>
            <NavItem to="videos">Videos</NavItem>
            <NavItem
              onClick={signOut}
              position="right"
            >
              Sign out
            </NavItem>
          </Navigation>
          <main className="admin-main">
            <Outlet />
          </main>
        </>
      )}
    </Authenticator>
  );
};

export default Admin;

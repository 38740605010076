import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer";
import Navigation, { NavItem } from "../../components/Navigation";
import "./styles.css";

const Portfolio = () => {
  return (
    <>
      <div style={{ position: "absolute", zIndex: 9999 }}>
        <Navigation>
          <NavItem to="layout">Layout</NavItem>
          <NavItem to="design">Design</NavItem>
          <NavItem to="3d">3D</NavItem>
          <NavItem to="lifedrawing">Life Drawing</NavItem>
          <NavItem position="right" style={{ paddingLeft: "10rem" }} />
        </Navigation>
      </div>
      <Outlet />
      <Footer />
    </>
  );
};

export default Portfolio;

import { motion } from "framer-motion";
import { useState } from "react";
import { Icon, TransitionablePortal } from "semantic-ui-react";
import { contactAPI } from "../../utils";
import "./styles.css";

const Contact = () => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const handleSubmit = async () => {
    const response = await contactAPI.create({
      name: `${firstname} ${lastname}`,
      email,
      phone,
      subject,
      message,
    });
    console.log(response);
    handleClear();
    setOpen(true);
  };
  const handleClear = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setSubject("");
    setMessage("");
  };
  return (
    <motion.div className="contact-form">
      <TransitionablePortal
        closeOnTriggerClick
        onClose={() => setOpen(false)}
        open={open}
      >
        <div
          style={{
            width: "28vw",
            height: "36vh",
            borderRadius: "2rem",
            left: "calc(50vw - (25vw / 2))",
            position: "fixed",
            top: "calc(50vh - (40vh / 2))",
            backgroundColor: "#eee",
            padding: "3vw",
            zIndex: 1000,
          }}
        >
          <h2 style={{ textAlign: "center" }}>
            <Icon name="paper plane" size="huge" />
            <div>Message Sent!</div>
            <div>I will respond you shortly once I see the message</div>
          </h2>
        </div>
      </TransitionablePortal>
      <h1 className="contact-form-title">Contact Me</h1>
      <div className="contact-form-body">
        <div className="contact-form-row">
          <span className="contact-input-field">
            <motion.span>First Name*</motion.span>
            <motion.input
              type="text"
              value={firstname}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </span>
          <span className="contact-input-field">
            <motion.span>Last Name*</motion.span>
            <motion.input
              type="text"
              value={lastname}
              onChange={(e) => setLastName(e.target.value)}
            />
          </span>
        </div>
        <div className="contact-form-row">
          <span className="contact-input-field">
            <motion.span>Email Address*</motion.span>
            <motion.input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </span>
          <span className="contact-input-field">
            <motion.span>Phone Number</motion.span>
            <motion.input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </span>
        </div>
        <span className="contact-input-field">
          <motion.span>Subject*</motion.span>
          <motion.input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </span>
        <br />
        <motion.span>Message*</motion.span>
        <motion.textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <motion.div className="contact-submit" onClick={handleSubmit}>
          Send Message
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Contact;

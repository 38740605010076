import astronaut from "./astronaut.png";
import hello from "./hello.png";
import helmet from "./helmet.png";
import logo from "./logo.png";
import project from "./project.jpg";
import samurai from "./samurai.png";
import starship from "./starship.png";
import software, { techstack } from "./software";
import social from "./social";

const images = {
  astronaut,
  hello,
  helmet,
  logo,
  project,
  samurai,
  starship,
  software,
  techstack,
  social,
};
export default images;

import { useEffect, useState } from "react";
import { File as FileType } from "../../../API";
import { ImageUpload } from "../../../components/Upload";
import { imageAPI } from "../../../utils";

interface ImageCardProp {
  image: FileType;
  fetchData: () => void;
}
const ImageCard = ({ image, fetchData }: ImageCardProp) => {
  const [display, setDisplay] = useState(false);
  const [src, setSrc] = useState<string>();
  return (
    <li className="image-list-item">
      <div>
        <div className="image-preview">
          {display && src && <img src={src} alt="preview" />}
        </div>
        {image.name} {image.info}
      </div>
      <button
        onClick={async () => {
          setDisplay(!display);
          if (!src) setSrc(await imageAPI.get(image.uri));
        }}
      >
        {display ? "Hide" : "Show"}
      </button>
      <button
        onClick={async () => {
          await imageAPI.delete(image.id, image.uri);
          fetchData();
        }}
      >
        Delete
      </button>
    </li>
  );
};

const ImageManager = () => {
  const [images, setImages] = useState<FileType[]>([]);
  useEffect(() => {
    fetchData();
    console.log(images);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchData = async () => {
    const { items } = await imageAPI.fetch();
    setImages(items);
  };
  return (
    <section>
      <h1>Image Manager</h1>
      <div>
        <h2>Images</h2>
        <div className="image-list-container">
          {images.length > 0 && (
            <ul className="image-list">
              {images.map((image, i) => (
                <ImageCard
                  {...{ image, fetchData }}
                  key={`image-list-item-${i}`}
                />
              ))}
            </ul>
          )}
          {images.length < 1 && <h1>No Image Available</h1>}
        </div>
      </div>
      <ImageUpload />
    </section>
  );
};

export default ImageManager;

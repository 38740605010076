import { useEffect, useState } from "react";

export const getStorageValue = (
  key: string,
  defaultValue: any,
  type: string
) => {
  if (typeof window !== "undefined") {
    const saved =
      type === "local"
        ? localStorage.getItem(key)
        : sessionStorage.getItem(key);
    return saved !== null ? JSON.parse(saved) : defaultValue;
  }
};

export const useLocalStorage = (key: string, defaultValue: any) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue, "local");
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export const useSessionStorage = (key: string, defaultValue: any) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue, "session");
  });

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

import Compress from "compress.js";

export const bytesToMegaBytes = (bytes: number) => bytes / 1024 ** 2;

export const generator = {
  genUid: (length = 16) => {
    let uid = "";
    const baseChar = "abcdefhijkmnprstwxyz2345678";
    const len = baseChar.length;
    const timestamp = new Date().valueOf();
    for (let i = 0; i < length; i++) {
      uid += baseChar.charAt(Math.floor((Math.random() * timestamp) % len));
    }
    return uid;
  },
  genGuid: () =>
    "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    }),
  genHash: (str: string) => {
    let hash = "";
    const baseChar = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
    const len = baseChar.length;
    const timestamp = new Date().valueOf();
    for (let i = 0; i < str.length; i++) {
      hash += baseChar.charAt(
        Math.floor(Math.random() * str.charCodeAt(i) * timestamp) % len
      );
    }
    return hash;
  },
  genRandNumber: (length = 100) => Math.floor(Math.random() * length),
  genRandColor: () => "#" + Math.random().toString(16).slice(2, 8),
};

export const processArtWorks = async (files: File[], project?: string) => {
  const image4K = await new Compress().compress(files, {
    maxWidth: 3840,
    maxHeight: 3840,
    size: 10,
  });
  const imageHD = await new Compress().compress(files, {
    maxWidth: 1920,
    maxHeight: 1920,
    size: 5,
  });
  const buildInputs = async () => {
    return files.map(async (file) => {
      const img = await new Promise<HTMLImageElement>((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => {
          const img = new Image();
          if (fileReader.result) img.src = fileReader.result?.toString();
          resolve(img);
        };
        fileReader.readAsDataURL(file);
      });
      const { width, height } = img;
      const projects = project ? [project] : [];
      return {
        id: generator.genGuid(),
        name: file.name,
        info: JSON.stringify({ width, height, size: file.size }),
        type: file.type,
        uri: file.name.toLowerCase().replace(" ", "_"),
        projects,
      };
    });
  };
  return {
    inputs: await Promise.all(await buildInputs()),
    image4K: image4K.map((img) =>
      Compress.convertBase64ToFile(img.data, img.ext)
    ),
    imageHD: imageHD.map((img) =>
      Compress.convertBase64ToFile(img.data, img.ext)
    ),
  };
};

import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer";
import Navigation from "../../components/Navigation";
import "./styles.css";

const Project = () => {
  return (
    <>
      <Navigation />
      <Outlet />
      <Footer />
    </>
  );
};

export default Project;

import { useEffect, useState } from "react";
import { Project, Tag as TagType } from "../../../API";
import images from "../../../assets/images";
import { Preview, Tag } from "../../../components/Widgets";
import { projectAPI, tagAPI, useSessionStorage } from "../../../utils";
import Details from "./Details";
const metaTag = ["project", "life-drawing", "3d", "design", "layout"];
const Gallery = () => {
  const [open, setOpen] = useState(false);
  const [projectIdx, setProjectIdx] = useState(0);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [displayProj, setDisplayProj] = useState<Project[]>([]);
  const [tags, setTags] = useSessionStorage("tag", []);
  const [projects, setProjects] = useSessionStorage("project", []);
  useEffect(() => {
    const fetchData = async () => {
      console.log("fetching Data...");
      const { items } = await projectAPI.fetch({
        filter: { tags: { contains: "project" } },
      });
      setProjects(items);
      setDisplayProj(items);
      if (tags.length < 1) {
        setTags(
          (await tagAPI.fetch()).items.filter(
            (tag) => !metaTag.includes(tag.value)
          )
        );
      }
    };
    if (projects.length < 1) fetchData();
    if (selectedTags.length < 1) setDisplayProj(projects);
    else {
      setDisplayProj(
        projects.filter(
          (project: Project) =>
            selectedTags.filter((tag) => project.tags.includes(tag)).length > 0
        )
      );
    }
    if (open) document.body.style.overflowY = "hidden";
    else document.body.style.overflowY = "auto";
    return () => {
      document.body.style.overflowY = "auto";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags, open]);
  const handleTagFilter = (tag: string) => {
    if (!selectedTags.includes(tag)) setSelectedTags([tag, ...selectedTags]);
    else {
      selectedTags.splice(selectedTags.indexOf(tag), 1);
      setSelectedTags([...selectedTags]);
    }
  };
  const handleDisplay = (idx: number) => {
    setOpen(true);
    setProjectIdx(idx);
  };
  const handleNext = () => {
    const next = projectIdx + 1;
    if (next < projects.length) setProjectIdx(next);
  };
  const handlePrevious = () => {
    const prev = projectIdx - 1;
    if (prev > -1) setProjectIdx(prev);
  };
  return (
    <>
      {open && (
        <div className="details-wrapper">
          <Details
            idx={projectIdx}
            max={projects.length}
            project={projects[projectIdx]}
            setOpen={setOpen}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
          />
        </div>
      )}
      <h1 className="project-page-title">Projects</h1>
      <div className="tag-select-bar">
        <Tag
          selected={selectedTags.length < 1}
          data={{
            key: "all",
            value: "all",
            text: "All Projects",
            color: "#fff",
          }}
          onClick={() => setSelectedTags([])}
        />
        {tags.map((tag: TagType) => (
          <div key={`tag-select-${tag.key}`}>
            <Tag
              data={tag}
              onClick={() => handleTagFilter(tag.value)}
              selected={selectedTags.includes(tag.value)}
            />
          </div>
        ))}
      </div>
      <section className="project-gallery">
        {displayProj.map((project, idx) => (
          <Preview
            key={project.id}
            project={project}
            tags={tags.filter((tag: TagType) =>
              project.tags.includes(tag.value)
            )}
            onClick={() => handleDisplay(idx)}
            handleFilter={handleTagFilter}
          />
        ))}
      </section>
      <h1 className="project-page-title">Other Projects</h1>
      <section>
        <div>
          <h2 className="project-section-title">This Website</h2>
          <div className="dummy-project">
            <img src={images.logo} alt="logo" />
            <div>
              <p>
                www.agateram.com/nixonyang is developed by myself using
                React.js and AWS Amplify. It functions as an extention of my
                resume and shows my ability in UI/UX design and web development.
              </p>
              <p>
                Please feel free to reach out to me through the Contact section
                if you have any thoughts and requests!
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Gallery;

import { useEffect, useState } from "react";
import { projectAPI } from "./gql";
import { useLocalStorage } from "./storage";

type Action = "refresh" | "create" | "update" | "delete";
interface ActionInput {
  id?: string;
  input?: any;
}

export const useProject = () => {
  const [projects, setProjects] = useLocalStorage("projects", []);

  useEffect(() => {
    handleProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchData = async () => {
    const { items } = await projectAPI.fetch();
    console.log(items);
    setProjects(items);
  };
  const handleProjects = async (
    action: Action = "refresh",
    data: ActionInput = {}
  ) => {
    let response;
    switch (action) {
      case "refresh":
        break;
      case "create":
        if (data.input) {
          response = await projectAPI.create(data.input);
        }
        break;
      case "update":
        if (data.id) {
          response = await projectAPI.delete(data.id);
        }
        break;
      case "delete":
        if (data.id) {
          response = await projectAPI.delete(data.id);
        }
        break;
      default:
    }
    await fetchData();
    return response;
  };
  return [projects, handleProjects];
};

export const useVideoPreview = (url: string) => {
  const [canvas, setCanvas] = useState<HTMLCanvasElement>();

  useEffect(() => {
    const video = document.createElement("video");
    video.src = url;
    const onLoad = () => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      video.currentTime = 1;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(video, 0, 0);
      setCanvas(canvas);
    };
    video.addEventListener("canplay", onLoad);
    return () => video.removeEventListener("load", onLoad);
  }, [url]);

  return canvas;
};

export const useLimitedRequests = (limit = 5, timePeriod = 6 * 1000) => {
  const [requests, setRequests] = useState<number[]>([]);
  return [
    requests,
    () => {
      const now = Date.now();
      if (requests.length > 0 && requests[0] < now - timePeriod)
        setRequests(requests.slice(1));
      if (requests.length < limit) {
        setRequests([...requests, now]);
        return now;
      }
      return 0;
    },
  ];
};

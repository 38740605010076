import { NavLink } from "react-router-dom";
import projects from "../../../assets/projects";
import "../styles.css";

export const layout = [
  { id: "l-0", uri: projects.mod1[0] },
  { id: "l-1", uri: projects.mod1[1] },
  { id: "l-2", uri: projects.mod1[2] },
  {
    id: "l-3",
    uri: "https://static.wixstatic.com/media/03ccfc_e1114e18c47f405cb9455ef12880f4b2~mv2.jpg/v1/fill/w_1583,h_1342,al_c,q_90,usm_0.66_1.00_0.01/03ccfc_e1114e18c47f405cb9455ef12880f4b2~mv2.webp",
  },
  { id: "l-4", uri: projects.mod3[0] },
  { id: "l-5", uri: projects.mod3[1] },
  { id: "l-6", uri: projects.mod3[2] },
  { id: "l-7", uri: projects.mod3[3] },
  {
    id: "l-8",
    uri: "https://static.wixstatic.com/media/03ccfc_70dda4735d8d4b1b838f017c5859e465~mv2.jpg/v1/fill/w_1423,h_921,al_c,q_85,usm_0.66_1.00_0.01/03ccfc_70dda4735d8d4b1b838f017c5859e465~mv2.webp",
  },
  {
    id: "l9",
    uri: "https://static.wixstatic.com/media/03ccfc_b98169365b63489e9473baae27659ba0~mv2.jpg/v1/fill/w_1423,h_921,al_c,q_85,usm_0.66_1.00_0.01/03ccfc_b98169365b63489e9473baae27659ba0~mv2.webp",
  },
  {
    id: "l10",
    uri: "https://static.wixstatic.com/media/03ccfc_f54355559c784a00951dac88d1ea1416~mv2.jpg/v1/fill/w_1297,h_839,al_c,q_85,usm_0.66_1.00_0.01/03ccfc_f54355559c784a00951dac88d1ea1416~mv2.webp",
  },
  {
    id: "l-11",
    uri: "https://static.wixstatic.com/media/03ccfc_098f851a9b0b49dcbaf4a2fb1e81562f~mv2.jpg/v1/fill/w_759,h_491,al_c,q_85,usm_0.66_1.00_0.01/03ccfc_098f851a9b0b49dcbaf4a2fb1e81562f~mv2.webp",
  },
  {
    id: "l-12",
    uri: "https://static.wixstatic.com/media/03ccfc_9f9c7d0d7ebd4610b4fb423bcf7cbb41~mv2.jpg/v1/fill/w_745,h_482,al_c,q_80,usm_0.66_1.00_0.01/03ccfc_9f9c7d0d7ebd4610b4fb423bcf7cbb41~mv2.webp",
  },
  {
    id: "l-13",
    uri: "https://static.wixstatic.com/media/03ccfc_f6369fcb938e4f30be5cdb60dbc7ff0a~mv2.jpg/v1/fill/w_936,h_1083,al_c,q_85,usm_0.66_1.00_0.01/03ccfc_f6369fcb938e4f30be5cdb60dbc7ff0a~mv2.webp",
  },
  {
    id: "l-15",
    uri: "https://static.wixstatic.com/media/03ccfc_ccab269badbb43b6a5a9410685cb6574~mv2.jpg/v1/fill/w_1891,h_551,al_c,q_85,usm_0.66_1.00_0.01/03ccfc_ccab269badbb43b6a5a9410685cb6574~mv2.webp",
    ext: [
      {
        id: "l14",
        uri: "https://static.wixstatic.com/media/03ccfc_c57a197bb26e4737851c135916540627~mv2.jpg/v1/fill/w_1812,h_528,al_c,q_85,usm_0.66_1.00_0.01/03ccfc_c57a197bb26e4737851c135916540627~mv2.webp",
      },
    ],
  },
  {
    id: "l16",
    uri: "https://static.wixstatic.com/media/03ccfc_1ba855fc6e1c4c448f91c499c1d68f50~mv2.jpg/v1/fill/w_1565,h_774,al_c,q_85,usm_0.66_1.00_0.01/03ccfc_1ba855fc6e1c4c448f91c499c1d68f50~mv2.webp",
  },
  {
    id: "l17",
    uri: "https://static.wixstatic.com/media/03ccfc_a6aafaef21dc44468c730c339ec67ee9~mv2.jpg/v1/fill/w_1828,h_641,al_c,q_85,usm_0.66_1.00_0.01/03ccfc_a6aafaef21dc44468c730c339ec67ee9~mv2.webp",
  },
];
const Layout = () => {
  return (
    <>
      <div className="portfolio-cover">
        <div
          className="portfolio-background"
          style={{ backgroundImage: `url(${projects.layout})` }}
        />
        <h1>Layout</h1>
      </div>
      <div className="portfolio-content-body">
        <div className="project-entity-board">
          <div className="project-file-list">
            {layout.slice(0, 4).map((img, i) => (
              <NavLink
                key={`layout-artwork-item-${img.id}-${i}`}
                to={`/artwork/${img.id}`}
              >
                <img alt="layout" src={img.uri} width="100%" />
              </NavLink>
            ))}
          </div>
        </div>
        <div className="project-entity-board">
          <div className="project-file-list">
            {layout.slice(4, 6).map((img, i) => (
              <NavLink
                key={`layout-artwork-item-${img.id}-${i}`}
                to={`/artwork/${img.id}`}
              >
                <img alt="layout" src={img.uri} width="100%" />
              </NavLink>
            ))}
          </div>
          <NavLink
            key={`layout-artwork-item-${layout[6].id}`}
            to={`/artwork/${layout[6].id}`}
          >
            <img alt="layout" src={layout[6].uri} width="60%" />
          </NavLink>
        </div>
        <div className="project-entity-board">
          <div className="project-file-list">
            {layout.slice(7, 10).map((img, i) => (
              <NavLink
                key={`layout-artwork-item-${img.id}-${i}`}
                to={`/artwork/${img.id}`}
              >
                <img alt="layout" src={img.uri} width="100%" />
              </NavLink>
            ))}
          </div>
          <div>
            {layout.slice(10, 14).map((img, i) => (
              <NavLink
                key={`layout-artwork-item-${img.id}-${i}`}
                to={`/artwork/${img.id}`}
              >
                <img alt="layout" src={img.uri} width="50%" />
              </NavLink>
            ))}
          </div>
        </div>
        <div className="project-entity-board">
          <div className="project-file-list">
            {layout.slice(14, 17).map((img, i) => (
              <NavLink
                key={`layout-artwork-item-${img.id}-${i}`}
                to={`/artwork/${img.id}`}
              >
                <img alt="layout" src={img.uri} width="100%" />
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;

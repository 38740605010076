import { motion } from "framer-motion";
import { LegacyRef, useRef, useState } from "react";
import { Menu, Visibility } from "semantic-ui-react";
import { ImageSlides } from "../../components/Slides";
import Footer from "../../components/Footer";
import About from "./sections/About";
import Portfolio from "./sections/Portfolio";
import Project from "./sections/Project";
import Contact from "./sections/Contact";
import { slides } from "./constants";
import images from "../../assets/images";
import "./styles.css";

const menuStyle = {
  border: "none",
  borderRadius: 0,
  boxShadow: "none",
  height: "4rem",
  position: "absolute",
  bottom: 0,
  width: "100vw",
  backgroundColor: "transparent",
  transition: "box-shadow 0.5s ease, padding 0.5s ease",
};

const fixedMenuStyle = {
  opacity: 0.9,
  height: "4rem",
  boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
};

const Home = () => {
  const [menuFixed, setMenuFixed] = useState(false);
  const homeRef = useRef<HTMLElement>();
  const aboutRef = useRef<HTMLElement>();
  const portfolioRef = useRef<HTMLElement>();
  const projectRef = useRef<HTMLElement>();
  const contactRef = useRef<HTMLElement>();
  return (
    <>
      <header
        className="home-header"
        ref={homeRef as LegacyRef<HTMLElement> | undefined}
      >
        {!menuFixed ? (
          <>
            <motion.div
              className="banner"
              animate={{ y: [0, -3, 0, 3, 0] }}
              onClick={() =>
                homeRef.current?.scrollIntoView({ behavior: "smooth" })
              }
              transition={{ duration: 2, repeat: Infinity, repeatType: "loop" }}
            >
              <img
                className="logo-img tiny"
                src={images.logo}
                alt="logo"
                style={{ width: "7rem", height: "7rem" }}
              />
            </motion.div>
            <div className="text-effect">
              Nixon
              <br />
              Yang
            </div>
            <ImageSlides images={slides} />
          </>
        ) : null}
        <img className="hello" src={images.hello} alt="hello dojo" />
      </header>
      <Visibility
        onBottomPassed={() => setMenuFixed(true)}
        onBottomVisible={() => setMenuFixed(false)}
        once={false}
      >
        <Menu
          inverted
          borderless
          className="home-menu"
          fixed={menuFixed ? "top" : undefined}
          style={menuFixed ? fixedMenuStyle : menuStyle}
        >
          <Menu.Item
            position="left"
            header
            onClick={() =>
              homeRef.current?.scrollIntoView({ behavior: "smooth" })
            }
          >
            {menuFixed ? (
              <>
                <motion.div
                  animate={{ y: [0, -3, 0, 3, 0] }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "loop",
                  }}
                >
                  <img
                    className="logo-img img avatar"
                    src={images.logo}
                    alt="avatar"
                  />
                </motion.div>
                <h4 className="author-name">Nixon Yang</h4>
              </>
            ) : null}
          </Menu.Item>
          <Menu.Item
            onClick={() =>
              homeRef.current?.scrollIntoView({ behavior: "smooth" })
            }
          >
            <span className="nav-item">Home</span>
          </Menu.Item>
          <Menu.Item
            onClick={() =>
              aboutRef.current?.scrollIntoView({ behavior: "smooth" })
            }
          >
            <span className="nav-item">About</span>
          </Menu.Item>
          <Menu.Item
            onClick={() =>
              portfolioRef.current?.scrollIntoView({ behavior: "smooth" })
            }
          >
            <span className="nav-item">Portfolio</span>
          </Menu.Item>
          <Menu.Item
            onClick={() =>
              projectRef.current?.scrollIntoView({ behavior: "smooth" })
            }
          >
            <span className="nav-item">Project</span>
          </Menu.Item>
          <Menu.Item
            onClick={() =>
              contactRef.current?.scrollIntoView({ behavior: "smooth" })
            }
          >
            <span className="nav-item">Contact</span>
          </Menu.Item>
          {menuFixed ? (
            <Menu.Item position="right" style={{ paddingLeft: "10rem" }} />
          ) : (
            <Menu.Item position="right" />
          )}
        </Menu>
      </Visibility>
      <main className="main-container">
        <About {...{ aboutRef }} />
        <div className="demo-reel-container">
          <iframe
          className="demo-reel"
            title="demo reel"
            src="https://player.vimeo.com/video/701552152?h=27ab411d6d?autoplay=1&loop=1&autopause=0"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          >
            Sorry, your browser does not support iframe :(
          </iframe>
        </div>
        <Portfolio {...{ portfolioRef }} />
        <Project {...{ projectRef }} />
        <Contact {...{ contactRef }} />
      </main>
      <Footer />
    </>
  );
};

export default Home;

import { lazy, Suspense, useEffect } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { Project } from "../../../API";
import projectsAsset from "../../../assets/projects";
import { projectAPI, useSessionStorage } from "../../../utils";

const Album = lazy(() => {
  return new Promise<any>((resolve) => {
    setTimeout(() => resolve(import("../../../components/Aulbum")), 300);
  });
});

const Design = () => {
  const [projects, setProjects] = useSessionStorage("design", []);
  useEffect(() => {
    const fetchData = async () => {
      const { items } = await projectAPI.fetch({
        filter: {
          tags: {
            contains: "design",
          },
        },
      });
      setProjects(items);
    };
    if (projects.length < 1) fetchData();
  });
  return (
    <>
      <div className="portfolio-cover">
        <div
          className="portfolio-background"
          style={{ backgroundImage: `url(${projectsAsset.design})` }}
        />
        <h1>Design</h1>
      </div>
      <div className="portfolio-content-body">
        {projects?.map((project: Project, i: number) => (
          <Suspense
            fallback={
              <div>
                <Dimmer active>
                  <Loader></Loader>
                </Dimmer>
                <div
                  style={{
                    width: "100%",
                    height: "10rem",
                    display: "flex",
                    overflow: "hidden",
                  }}
                />
              </div>
            }
            key={`key-project-item-${i}`}
          >
            <Album project={project} />
          </Suspense>
        ))}
      </div>
    </>
  );
};

export default Design;

import layout from "./Layout.jpg";
import threed from "./3d.jpg";
import lifedrawing from "./LifeDrawing.jpg";
import design from "./Design.jpg";
// mod 1
import mod11 from "./mod1/Module 1-Final_thumb.jpg";
import mod12 from "./mod1/Puming_Yang_ANI1627_MOD1_Futurestic Underground City_Rough_thumb.jpg";
import mod13 from "./mod1/Puming_Yang_ANI1627_MOD1_Futurestic Underground City_Size Relationship_thumb.jpg";
import mod14 from "./mod1/Puming_Yang_ANI1627_MOD1_Futurestic Underground City_Thumbnails_thumb.jpg";
// mod 2
import mod21 from "./mod2/Puming_Yang_ANI1627_MOD1_Character Design_Thumbnails_thumb.jpg";
import mod22 from "./mod2/Puming_Yang_ANI1627_MOD2_Character Design_Expression Clean_thumb.jpg";
import mod23 from "./mod2/Puming_Yang_ANI1627_MOD2_Character Design_Expressions Rough_thumb.jpg";
import mod24 from "./mod2/Puming_Yang_ANI1627_MOD2_Character Design_Poses Clean_thumb.jpg";
import mod25 from "./mod2/Puming_Yang_ANI1627_MOD2_Character Design_Poses Rough_thumb.jpg";
import mod26 from "./mod2/Puming_Yang_ANI1627_MOD2_Character Design_Rotation Clean_thumb.jpg";
import mod27 from "./mod2/Puming_Yang_ANI1627_MOD2_Character Design_Rotation Rough_thumb.jpg";
// mod 3
import mod31 from "./mod3/Puming_Yang_ANI1627_MOD3_LocationDesign_Base_Clean_thumb.jpg";
import mod32 from "./mod3/Puming_Yang_ANI1627_MOD3_LocationDesign_Ruin_Clean_thumb.jpg";
import mod33 from "./mod3/Puming_Yang_ANI1627_MOD3_LocationDesign_SuperThumbs_thumb.jpg";
import mod34 from "./mod3/Puming_Yang_ANI1627_MOD3_LocationDesign_Thumbnails_thumb.jpg";

const mod1 = [mod11, mod12, mod13, mod14];
const mod2 = [mod21, mod22, mod23, mod24, mod25, mod26, mod27];
const mod3 = [mod31, mod32, mod33, mod34];
const projects = {
  layout,
  threed,
  lifedrawing,
  design,
  mod1,
  mod2,
  mod3,
};
export default projects;

import projects from "../../../assets/projects";

const lifedrawing = [
  {
    id: "ld1",
    uri: "https://static.wixstatic.com/media/03ccfc_6665d90eb9234348b06e17f4133962a3~mv2.jpg/v1/fill/w_1544,h_741,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/03ccfc_6665d90eb9234348b06e17f4133962a3~mv2.jpg",
  },
  {
    id: "ld2",
    uri: "https://static.wixstatic.com/media/03ccfc_a0a9ee719aef47918ccbff1510adb4bb~mv2.jpg/v1/fill/w_1544,h_741,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/03ccfc_a0a9ee719aef47918ccbff1510adb4bb~mv2.jpg",
  },
  {
    id: "ld3",
    uri: "https://static.wixstatic.com/media/03ccfc_a7004b1aff9640f78978bc410969d4e1~mv2.jpg/v1/fill/w_600,h_808,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Yang_P_LDIV_Construction.jpg",
  },
  {
    id: "ld4",
    uri: "https://static.wixstatic.com/media/03ccfc_0e74ad50f6f64124b74e72b32dd8beaf~mv2.jpg/v1/fill/w_600,h_805,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Yang_P_Cast.jpg",
  },
  {
    id: "ld5",
    uri: "https://static.wixstatic.com/media/03ccfc_e8da56368c724be9857c0c770e71f563~mv2.jpg/v1/fill/w_600,h_805,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Yang_P_Skull.jpg",
  },
  {
    id: "ld6",
    uri: "https://static.wixstatic.com/media/03ccfc_f0e9f59660f642bab7fa29f30a5a0cb6~mv2.jpg/v1/fill/w_600,h_805,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Yang_P_Portrait.jpg",
  },
  {
    id: "ld7",
    uri: "https://static.wixstatic.com/media/03ccfc_a285b5d3206540ceb1b67cf17044e99d~mv2.jpg/v1/fill/w_600,h_791,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Yang_P_LDIV_Long%20Pose%20Skeleton.jpg",
  },
  {
    id: "ld8",
    uri: "https://static.wixstatic.com/media/03ccfc_9fdcfb653a0e4b04867458a3912ae90a~mv2.jpg/v1/fill/w_600,h_800,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/P%20Blair%20Hands%20Practice%20P2.jpg",
  },
  {
    id: "ld9",
    uri: "https://static.wixstatic.com/media/03ccfc_345d8acfd32d4a0da36f02ab108c38fa~mv2.jpg/v1/fill/w_600,h_805,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/SelfPractice-1.jpg",
  },
  {
    id: "ld10",
    uri: "https://static.wixstatic.com/media/03ccfc_1e267ac2f9eb4e23855b67ecac773a4a~mv2.jpg/v1/fill/w_600,h_805,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/SelfPractice-2.jpg",
  },
  {
    id: "ld11",
    uri: "https://static.wixstatic.com/media/03ccfc_c5ac95d24f0945f1bf1d074eae56055e~mv2.jpg/v1/fill/w_600,h_1601,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Yang_P_LDIV_Gestures.jpg",
  },
  {
    id: "ld12",
    uri: "https://static.wixstatic.com/media/03ccfc_cf63f78087c34e1aa953d61b227f5d0d~mv2.jpg/v1/fill/w_363,h_600,al_c,q_80,usm_0.66_1.00_0.01/03ccfc_cf63f78087c34e1aa953d61b227f5d0d~mv2.webp",
  },
  {
    id: "ld13",
    uri: "https://static.wixstatic.com/media/03ccfc_dcda6ba4debd4a82999c830d5eca9ac6~mv2.jpg/v1/fill/w_449,h_600,al_c,q_80,usm_0.66_1.00_0.01/03ccfc_dcda6ba4debd4a82999c830d5eca9ac6~mv2.webp",
  },
];
const LifeDrawing = () => {
  return (
    <>
      <div className="portfolio-cover">
        <div
          className="portfolio-background"
          style={{ backgroundImage: `url(${projects.lifedrawing})` }}
        />
        <h1>Life Drawing</h1>
      </div>
      <div className="portfolio-content-body">
        <div className="project-entity-board">
          <div className="project-file-list">
            {lifedrawing.slice(0, 2).map((img, i) => (
              <img
                alt="lifedrawing"
                key={`image-key-item-${img.id}-${i}`}
                src={img.uri}
                width="100%"
              />
            ))}
          </div>
          <div className="project-file-list">
            {lifedrawing.slice(2, 10).map((img, i) => (
              <img
                alt="lifedrawing"
                key={`image-key-item-${img.id}-${i}`}
                src={img.uri}
                width="25%"
              />
            ))}
          </div>
          <div className="project-file-list"></div>
          <div
            className="project-file-list"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img alt="lifedrawing" src={lifedrawing[10].uri} width="50%" />
            <div style={{ width: "50%" }}>
              <img alt="lifedrawing" src={lifedrawing[11].uri} width="100%" />
              <img alt="lifedrawing" src={lifedrawing[12].uri} width="100%" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LifeDrawing;

import { MutableRefObject, RefObject } from "react";
import { motion } from "framer-motion";
import { blocks, cardVariants } from "../constants";
import { NavLink } from "react-router-dom";

interface Props {
  portfolioRef: MutableRefObject<HTMLElement | undefined>;
}

interface CardProps {
  emoji: string;
  hueA: number;
  hueB: number;
}
const hue = (h: number) => `hsl(${h}, 100%, 50%)`;

function Card({ emoji, hueA, hueB }: CardProps) {
  const background = `linear-gradient(306deg, ${hue(hueA)}, ${hue(hueB)})`;

  return (
    <motion.div
      className="card-container"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.8 }}
    >
      <div className="splash" style={{ background }} />
      <motion.div className="card" variants={cardVariants}>
        {emoji}
      </motion.div>
    </motion.div>
  );
}

export const PopCardList = () => (
  <>
    <div className="card-list-wrapper">
      <div className="card-wrapper">
        <Card emoji={"🍆"} hueA={220} hueB={260} />
      </div>
      <div className="card-wrapper">
        <Card emoji={"🍋"} hueA={260} hueB={290} />
      </div>
      <div className="card-wrapper">
        <Card emoji={"🍇"} hueA={240} hueB={280} />
      </div>
    </div>
    <div className="card-list-wrapper">
      <div className="card-wrapper">
        <Card emoji={"🍆"} hueA={220} hueB={260} />
      </div>
      <div className="card-wrapper">
        <Card emoji={"🍋"} hueA={260} hueB={290} />
      </div>
      <div className="card-wrapper">
        <Card emoji={"🍇"} hueA={240} hueB={280} />
      </div>
    </div>
  </>
);

interface BlockProps {
  name: string;
  cover: string;
  link: string;
}

const Block = ({ name, cover, link }: BlockProps) => (
  <NavLink to={link}>
    <div className="block-body" style={{ backgroundImage: `url(${cover})` }}>
      <div className="block-cover">
        <h2 className="block-name">{name}</h2>
      </div>
    </div>
  </NavLink>
);

const Portfolio = ({ portfolioRef }: Props) => (
  <section
    className="portfolio-container"
    ref={portfolioRef as RefObject<HTMLElement>}
  >
    <h1 className="portfolio-title">Portfolio</h1>
    <div className="portfolio-body">
      {blocks.map(({ name, cover, link }, idx) => (
        <Block {...{ name, cover, link }} key={`portfolio-block-${idx}`} />
      ))}
    </div>
  </section>
);
export default Portfolio;

import { MouseEvent, useEffect, useState } from "react";
import { Button, Icon, TransitionablePortal } from "semantic-ui-react";
import { Project, Tag as TagType } from "../../API";
import { fileAPI, imageAPI, useSessionStorage } from "../../utils";
import "./styles.css";

const reverseColor = (color: string) => {
  const newColor =
    "000000" + (0xffffff - Number("0x" + color.substring(1, color.length)));
  return "#" + newColor.substring(newColor.length - 6, newColor.length);
};

interface TagProps {
  data: {
    key: string;
    value: string;
    text: string;
    color: string;
  };
  onClick?: () => void;
  selected?: true | false;
}
export const Tag = ({ data, selected, onClick, ...props }: TagProps) => {
  const handleEnter = (e: MouseEvent<HTMLDivElement>) => {
    if (!selected) {
      const container = e.currentTarget;
      container.style.color = reverseColor(data.color);
      container.style.background = data.color;
    }
  };
  const handleLeave = (e: MouseEvent<HTMLDivElement>) => {
    if (!selected) {
      const container = e.currentTarget;
      container.style.color = data.color;
      container.style.background = "none";
    }
  };
  return (
    <div
      className="entity-tag"
      key={data.key}
      style={{
        border: `0.2rem solid ${data.color}`,
        background: selected ? data.color : "none",
        color: selected ? reverseColor(data.color) : data.color,
      }}
      onMouseOver={handleEnter}
      onMouseLeave={handleLeave}
      onClick={onClick}
      {...props}
    >
      {data.text}
    </div>
  );
};
export const PopupBox = ({
  title = "title",
  confirmCallback = () => {},
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(!open);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Button
        content={open ? "Cancel" : "Confirm"}
        negative={open}
        positive={!open}
        onClick={handleClick}
      />
      <TransitionablePortal onClose={handleClose} open={open}>
        <div
          style={{
            left: 0,
            right: 0,
            margin: "0 auto",
            position: "fixed",
            top: "40%",
            width: "max-content",
            height: "max-content",
            zIndex: 1000,
          }}
        >
          <h1>{title}</h1>
          <div>{props.children}</div>
          <Button.Group floated="right">
            <Button onClick={handleClose}>Cancel</Button>
            <Button.Or text="or" />
            <Button
              positive
              onClick={() => {
                confirmCallback();
                handleClose();
              }}
            >
              Confirm
            </Button>
          </Button.Group>
        </div>
      </TransitionablePortal>
    </>
  );
};

interface PreviewProps {
  project: Project;
  tags: TagType[];
  handleFilter?: (tag: string) => void;
  onClick: () => void;
}

export const Preview = ({
  project,
  tags,
  handleFilter,
  onClick,
}: PreviewProps) => {
  const [url, setUrl] = useSessionStorage(`${project.id}-${project.files[0]}`, "");
  const [type, setType] = useState<string>("image");
  const [hover, setHover] = useState("hidden");
  useEffect(() => {
    const fetchData = async () => {
      const [cover] = (
        await fileAPI.fetch({
          filter: { uri: { eq: project.files[0] } },
        })
      ).items;
      setType(cover.type);
      if (cover.type.includes("image"))
        setUrl(await imageAPI.get(project.files[0] as string));
      if (cover.type.includes("video")) {
        setUrl("");
      }
    };
    if (url === "") fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);
  return (
    <>
      <div
        className="project-prview"
        style={{ backgroundImage: `url(${url})` }}
        onMouseEnter={() => setHover("visible")}
        onMouseLeave={() => setHover("hidden")}
        onClick={onClick}
      >
        {!url && <h3 style={{ fontSize: "2rem" }}>No Preview</h3>}
        {type.includes("video") && (
          <h3>
            <Icon name="video play" />
          </h3>
        )}
        <div className={`projec-info ${hover}`}>
          <h2 className="project-preview-title">{project.name}</h2>
          <div className="tag-preview-list">
            {tags.map((tag) => (
              <Tag
                key={tag.key}
                data={tag}
                onClick={() => {
                  if (handleFilter) handleFilter(tag.value);
                }}
              />
            ))}
          </div>
          <div className="project-preview-background" />
        </div>
      </div>
    </>
  );
};

import { Variants } from "framer-motion";
import projects from "../../assets/projects";
import pan from "../../assets/projects/pan.jpg";

export const profileVariants: Variants = {
  offscreen: {
    y: 300,
  },
  onscreen: {
    y: 0,
    rotate: 10,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

export const astronautVariants: Variants = {
  offscreen: {
    x: 300,
  },
  onscreen: {
    x: 200,
    y: -30,
    rotate: -20,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 2,
    },
  },
};

export const rightSlideVariants: Variants = {
  offscreen: {
    x: 300,
  },
  onscreen: {
    x: 50,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

export const upSlideVariants: Variants = {
  offscreen: {
    y: 300,
  },
  onscreen: {
    y: 0,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

export const cardVariants: Variants = {
  offscreen: {
    y: 300,
  },
  onscreen: {
    y: 50,
    rotate: -10,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

export const slides = [
  pan,
  "https://static.wixstatic.com/media/03ccfc_d3880f22fdd747138e4b462a101dd4d8~mv2.jpg/v1/fill/w_1326,h_958,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/03ccfc_d3880f22fdd747138e4b462a101dd4d8~mv2.jpg",
  "https://static.wixstatic.com/media/03ccfc_558b4f5852f54c89b2f96b1445dee354~mv2.jpg/v1/fill/w_1326,h_958,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/03ccfc_558b4f5852f54c89b2f96b1445dee354~mv2.jpg",
];

export const blocks = [
  { name: "Layout", cover: projects.layout, link: "portfolio/layout" },
  { name: "Design", cover: projects.design, link: "portfolio/design" },
  { name: "3D", cover: projects.threed, link: "portfolio/3d" },
  {
    name: "Life Drawing",
    cover: projects.lifedrawing,
    link: "/portfolio/lifedrawing",
  },
];

import { MutableRefObject, useEffect, useRef, useState } from "react";
import { Button, Dropdown, Icon, Input, Tab } from "semantic-ui-react";
import { File, Project, Tag } from "../../../API";
import {
  ImageUpload,
  ModelUpload,
  VideoUpload,
} from "../../../components/Upload";
import {
  fileAPI,
  generator,
  layoutList,
  tagAPI,
  useProject,
} from "../../../utils";

const ProjectManager = () => {
  const [projects, handleProjects] = useProject();
  const [tagList, setTagList] = useState<Tag[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [grid, setGrid] = useState({});
  const [layout, setLayout] = useState(-1);
  const [id, setID] = useState(generator.genUid(8));
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const imageUploadRef = useRef<{
    handleSubmit: (projectID: string) => string[];
  }>();
  const modelUploadRef = useRef<{
    handleSubmit: (projectID: string) => string[];
  }>();
  const videoUploadRef = useRef<{
    handleSubmit: (projectID: string) => string[];
  }>();
  useEffect(() => {
    fetchData();
    handleProjects();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const clearField = () => {
    setFiles([]);
    setName("");
    setDescription("");
    setID(generator.genUid(8));
  };
  const fetchData = async () => {
    setTagList((await tagAPI.fetch()).items);
  };
  const handleSubmit = async () => {
    const imageUpload = imageUploadRef.current;
    const modelUpload = modelUploadRef.current;
    const videoUpload = videoUploadRef.current;
    let fileIDs: string[] = [];
    files.forEach((item) => fileIDs.push(item.id));
    const responses = [
      await imageUpload?.handleSubmit(id),
      await modelUpload?.handleSubmit(id),
      await videoUpload?.handleSubmit(id),
    ];
    console.log(responses);
    responses.forEach((response) => {
      if (response) response.forEach((item) => fileIDs.push(item));
    });
    tagList.forEach((item) => {
      if (tags.includes(item.id)) {
        item.projects.push(id);
        item.projects = Array.from(new Set(item.projects));
        const { projects } = item;
        tagAPI.update({ id: item.id, projects });
      }
    });
    files.forEach((item) => {
      if (tags.includes(item.id)) {
        item.projects.push(id);
        item.projects = Array.from(new Set(item.projects));
        const { projects } = item;
        fileAPI.update({ id: item.id, projects });
      }
    });
    const reslt = await handleProjects("create", {
      input: {
        id,
        name,
        info: JSON.stringify({ description, grid, layout }),
        tags,
        files: fileIDs,
      },
    });
    console.log(reslt);
    clearField();
  };
  const panes = [
    {
      menuItem: "Upload Image",
      render: () => (
        <Tab.Pane className="upload-tab" attached={false}>
          <ImageUpload
            disableSubmit
            ref={imageUploadRef as unknown as MutableRefObject<any>}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Upload Model",
      render: () => (
        <Tab.Pane className="upload-tab" attached={false}>
          <ModelUpload
            disableSubmit
            ref={modelUploadRef as unknown as MutableRefObject<any>}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Upload Video",
      render: () => (
        <Tab.Pane className="upload-tab" attached={false}>
          <VideoUpload
            disableSubmit
            ref={videoUploadRef as unknown as MutableRefObject<any>}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Add File Entity",
      render: () => (
        <Tab.Pane className="upload-tab" attached={false}>
          <VideoUpload
            disableSubmit
            ref={videoUploadRef as unknown as MutableRefObject<any>}
          />
        </Tab.Pane>
      ),
    },
  ];
  return (
    <section>
      <h2>Project Manager</h2>
      <Button primary onClick={async () => await handleProjects()}>
        Refresh
      </Button>
      <div style={{ width: "100%" }}>
        <div>
          <div>
            {projects.map((item: Project) => (
              <div key={item.id}>
                <div>{item.name}</div>
                <div>{new Date(item.createdAt).toString() }</div>
                <div>
                  <Button
                    onClick={async () =>
                      await handleProjects("delete", { id: item.id })
                    }
                  >
                    Delete
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <h3>
            Create Project <Icon name="microchip" />
          </h3>
          <div>
            <Input
              fluid
              label="Name"
              onChange={(e) => setName(e.currentTarget.value)}
            />
            <div>
              <Input
                fluid
                label="Descriptions"
                onChange={(e) => setDescription(e.currentTarget.value)}
              />
              <Dropdown
                lazyLoad
                placeholder="Tags"
                fluid
                multiple
                selection
                onChange={(e: any, { value }: any) => setTags(value)}
                options={tagList}
              />
              <Dropdown
                lazyLoad
                placeholder="Layout"
                fluid
                selection
                value={layout}
                onChange={(e: any, { value }: any) => setLayout(value)}
                options={layoutList}
              />
            </div>
            <Input
              label="Project Grid"
              onChange={(e) => setGrid(JSON.parse(e.currentTarget.value))}
            />
          </div>
          <h2>Upload New Files</h2>
          <Tab
            menu={{
              color: "white",
              inverted: true,
              secondary: true,
              pointing: true,
            }}
            panes={panes}
          />
          <div className="button-panel">
            <Button positive floated="right" onClick={handleSubmit}>
              Create
            </Button>
            <Button floated="right" onClick={clearField}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectManager;

import ae from "./ae.svg";
import ai from "./ai.svg";
import blender from "./blender.svg";
import harmony from "./harmony.svg";
import clipstuidio from "./clipstuidio.svg";
import marmoset from "./marmoset.svg";
import maya from "./maya.svg";
import pr from "./pr.png";
import ps from "./ps.svg";
import react from "./react.svg";
import python from "./python.svg";
import subtance from "./substance.svg";
import unity from "./unity.svg";
import unreal from "./ue.svg";
import xd from "./xd.svg";
import zbrush from "./zbrush.svg";

export const techstack = [
  { logo: ps, level: 5 },
  { logo: blender, level: 5 },
  { logo: ai, level: 4 },
  { logo: clipstuidio, level: 4 },
  { logo: zbrush, level: 4 },
  { logo: maya, level: 4 },
  { logo: xd, level: 4 },
  { logo: subtance, level: 3.5 },
  { logo: ae, level: 3 },
  { logo: react, level: 3 },
  { logo: python, level: 3 },
  { logo: marmoset, level: 3 },
  { logo: harmony, level: 3 },
  { logo: unity, level: 3 },
  { logo: unreal, level: 2 },
];

const software = {
  ae,
  ai,
  blender,
  clipstuidio,
  marmoset,
  maya,
  pr,
  ps,
  subtance,
  unity,
  unreal,
  xd,
  zbrush,
};
export default software;

import { MutableRefObject, RefObject } from "react";
import { motion } from "framer-motion";
import { Icon } from "semantic-ui-react";
import images from "../../../assets/images";
import {
  astronautVariants,
  profileVariants,
  rightSlideVariants,
  upSlideVariants,
} from "../constants";
import { Rating } from "@aws-amplify/ui-react";

interface Props {
  aboutRef: MutableRefObject<HTMLElement | undefined>;
}

const About = ({ aboutRef }: Props) => (
  <section className="about-container" ref={aboutRef as RefObject<HTMLElement>}>
    <motion.div
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.8 }}
      className="intro-container"
    >
      <div className="left-board">
        <motion.div className="profile-pic" variants={profileVariants}>
          <img
            src={images.logo}
            alt="logo"
            style={{ width: "200px", height: "200px" }}
          />
        </motion.div>
        <motion.div className="info">
          <h1 className="hero">Nixon Yang</h1>
          <h3 className="social-link">
            <a
              style={{ color: "white", display: "flex" }}
              href="mailto:yangp1152231@gmail.com"
            >
              <Icon name="mail" />
              {"   "}
              yangp1152231@gmail.com
            </a>
            <a
              className="social-logo"
              style={{ color: "white", display: "flex" }}
              target="_blank"
              href="https://www.linkedin.com/in/yangp1152231/"
              rel="noreferrer"
            >
              <Icon name="linkedin" />
              Nixon Yang
            </a>
          </h3>
          <div className="social-media"></div>
        </motion.div>
      </div>
      <div className="right-board">
        <motion.div className="about-content" variants={rightSlideVariants}>
          <motion.h1 className="about-title">About</motion.h1>
          <p>
            Hello! My name is Nixon Yang, I'm a graduate of the Animation
            program at Algonquin College.
          </p>
          <p>
            I'm experienced in Animation, Layout, Character Design, 3D, Life
            Drawing, Web Design, etc. I also have broad experience to various
            software and tools. Please see the portfolio section below for more
            details.
          </p>

          <motion.div className="software" variants={upSlideVariants}>
            {images.techstack.map(({ logo, level }, i) => (
              <div className="software-level" key={`techstack-img-${i}`}>
                <motion.img className="software-logo" src={logo} />
                <Rating
                  value={level}
                  fillColor="white"
                  emptyColor="black"
                  size="small"
                  style={{ gap: "1px", fontSize: "1.2rem" }}
                />
              </div>
            ))}
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
    <motion.div
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.8 }}
      className="astronaut-container"
    >
      <motion.div
        className="astronaut"
        animate={{ x: [0, -3, 0, 3, 0], y: [0, 3, 0, -3, 0] }}
        transition={{
          duration: 2,
          repeat: Infinity,
          repeatType: "loop",
        }}
      >
        <motion.img
          alt="astronaut"
          src={images.astronaut}
          variants={astronautVariants}
        />
      </motion.div>
    </motion.div>
  </section>
);

export default About;

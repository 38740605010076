import twitter from "./twitter.svg";
import linkedin from "./linkedin.svg";
import facebook from "./facebook.svg";
import instagram from "./instagram.svg";

const social = {
  twitter,
  linkedin,
  facebook,
  instagram,
};
export default social;

import { useEffect, useState } from "react";
import { Button, Input, Portal } from "semantic-ui-react";
import { Tag as TagType } from "../../../API";
import { Tag } from "../../../components/Widgets";
import { generator, tagAPI, useLocalStorage } from "../../../utils";

const TagManager = () => {
  const [tags, setTags] = useLocalStorage("tags", []);
  const [mode, setMode] = useState<"edit" | "new">("new");
  const [text, setText] = useState("sample");
  const [color, setColor] = useState("#ffffff");
  const [idx, setIdx] = useState(0);
  const [tagId, setId] = useState("");
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (tags.length < 1) fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchData = async () => {
    const { items } = await tagAPI.fetch();
    setTags(items);
    console.log(items);
  };
  const handleEdit = async (idx: number) => {
    const tag = tags[idx];
    setMode("edit");
    setText(tag.text);
    setColor(tag.color);
    setIdx(idx);
  };
  const handleCancel = () => {
    setMode("new");
    setIdx(0);
    setText("sample");
    setColor("#ffffff");
  };
  const handleDelete = async () => {
    const response = await tagAPI.delete(tagId);
    console.log(response);
    fetchData();
  };
  const handleSubmit = async () => {
    const value = text.toLowerCase().replace(" ", "-");
    if (mode === "new") {
      const response = await tagAPI.create({
        id: value,
        key: generator.genUid(6),
        value,
        text,
        color,
        projects: [],
      });
      console.log(response);
    } else {
      const { id } = tags[idx];
      const response = await tagAPI.update({
        id,
        text,
        value: text.toLowerCase().replace(" ", "-"),
        color,
      });
      console.log(response);
      setMode("new");
    }
    fetchData();
  };
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  return (
    <section className="tag-manager">
      <Portal onClose={handleClose} open={open}>
        <div
          style={{
            left: 0,
            right: 0,
            margin: "0 auto",
            position: "fixed",
            top: "40%",
            width: "30rem",
            zIndex: 1000,
          }}
        >
          <h1>Are you sure?</h1>
          <p>This cannot be undone if you click 'Delete'.</p>
          <p>To close, simply click the 'Cancel' button or click away.</p>
          <Button.Group floated="right">
            <Button onClick={handleClose}>Cancel</Button>
            <Button.Or text="or" />
            <Button
              negative
              onClick={() => {
                handleDelete();
                handleClose();
              }}
            >
              Delete
            </Button>
          </Button.Group>
        </div>
      </Portal>
      <h2>Tag Manager</h2>
      <Button primary onClick={fetchData}>
        Refresh
      </Button>
      <ul className="tag-item-list">
        {tags.length < 1 && <h3>No Tag Available</h3>}
        {tags.map((tag: TagType, i: number) => (
          <li className="tag-item" key={tag.key}>
            <Tag data={tag} />
            <div className="tag-item-btn-group">
              <button className="tag-delete-btn" onClick={() => handleEdit(i)}>
                Edit
              </button>
              <button
                className="tag-delete-btn"
                onClick={() => {
                  setId(tag.id);
                  handleOpen();
                }}
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
      <div className="add-tag">
        <h3>{mode === "new" ? "Create a new Tag" : "Edit the Tag"}</h3>
        <div className="preview-tag">
          Preview
          <Tag data={{ key: "test", text, value: text, color }} />
        </div>
        <div className="add-panel">
          <Input
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Enter a tag name"
          />
          <input
            type="color"
            name="color"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
          <Button positive onClick={handleSubmit}>
            {mode === "new" ? "Add" : "Update"}
          </Button>
          {mode === "edit" && <Button onClick={handleCancel}>Cancel</Button>}
        </div>
      </div>
    </section>
  );
};

export default TagManager;

import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import Portfolio from "./routes/Portfolio";
import Project from "./routes/Project";
import Layout from "./routes/Portfolio/pages/Layout";
import Design from "./routes/Portfolio/pages/Design";
import ThreeD from "./routes/Portfolio/pages/ThreeD";
import LifeDrawing from "./routes/Portfolio/pages/LifeDrawing";
import Artwork from "./routes/Artwork";
import Admin from "./routes/Admin";
import TagManager from "./routes/Admin/pages/TagManager";
import ProjectManager from "./routes/Admin/pages/ProjectManager";
import ImageManager from "./routes/Admin/pages/ImageManager";
import Dashboard from "./routes/Admin/pages/Dashboard";
import Gallery from "./routes/Project/pages/Gallery";
import "./App.css";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="portfolio" element={<Portfolio />}>
        <Route path="layout" element={<Layout />} />
        <Route path="design" element={<Design />} />
        <Route path="3d" element={<ThreeD />} />
        <Route path="lifedrawing" element={<LifeDrawing />} />
      </Route>
      <Route path="project" element={<Project />}>
        <Route index element={<Gallery />} />
        <Route path=":id" element={<Gallery />} />
      </Route>
      <Route path="artwork/:id" element={<Artwork />} />
      <Route path="schwifty" element={<Admin />}>
        <Route index element={<Dashboard />} />
        <Route path="tags" element={<TagManager />} />
        <Route path="projects" element={<ProjectManager />} />
        <Route path="images" element={<ImageManager />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default App;

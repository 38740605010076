import { LegacyRef, useEffect, useRef } from "react";
import "./styles.css";

const Dropbox = ({
  handleFiles = (files: File[]) => {
    console.log(files);
  },
  singleFile = false,
  ...props
}) => {
  const dropboxRef = useRef<HTMLDivElement>();
  const inputRef = useRef<HTMLInputElement>();
  useEffect(() => {
    const dropbox = dropboxRef.current;
    if (dropbox) {
      if (typeof FileReader == "undefined") {
        alert("<p>Your Browser Does Not Support FileReader :(</p>");
        dropbox.setAttribute("disabled", "disabled");
      }
      document.ondragover = (e) => e.preventDefault();
      document.ondrop = (e) => e.preventDefault();
      dropbox.ondragover = (e) => e.preventDefault();
      dropbox.ondrop = (e) => {
        if (e.dataTransfer?.files) handleFileList(e.dataTransfer.files);
      };
    }
  });
  const handleFileList = (fileList: FileList) => {
    const files = [];
    if (singleFile) files[0] = fileList[0];
    else
      for (let i = 0; i < fileList.length; i++) {
        files.push(fileList[i]);
      }
    handleFiles(files);
  };
  return (
    <>
      <div
        ref={dropboxRef as LegacyRef<HTMLDivElement>}
        className="dropbox"
        onClick={() => inputRef?.current?.click()}
        {...props}
      >
        {props.children}
      </div>
      <input
        ref={inputRef as LegacyRef<HTMLInputElement>}
        style={{ display: "none" }}
        type="file"
        onChange={() => {
          if (inputRef?.current?.files) handleFileList(inputRef.current.files);
        }}
        multiple={!singleFile}
      />
    </>
  );
};

export default Dropbox;

import { lazy, Suspense, useEffect } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { Project } from "../../../API";
import projectsAsset from "../../../assets/projects";
import { projectAPI, useSessionStorage } from "../../../utils";

const images = [
  "https://static.wixstatic.com/media/03ccfc_f181119826c74dc08a5ab9a3ea3b43b5~mv2.png/v1/fill/w_978,h_558,al_c,usm_0.66_1.00_0.01,enc_auto/Wagon-Wireframe.png",
  "https://static.wixstatic.com/media/03ccfc_2ada2ccabdbc48dc89b6a82fdc9a7de8~mv2.png/v1/fill/w_978,h_558,al_c,usm_0.66_1.00_0.01,enc_auto/Wagon-Rendered.png",
  "https://static.wixstatic.com/media/03ccfc_aa0b223b6eeb429485aa3b0f94ddda0a~mv2.jpg/v1/fill/w_839,h_1298,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Assignment%232_Yang_P_Poster.jpg",
  "https://static.wixstatic.com/media/03ccfc_d3880f22fdd747138e4b462a101dd4d8~mv2.jpg/v1/fill/w_1365,h_768,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/G_PR6_Yang%EF%BC%8C%20P_Final%20render.jpg",
  "https://static.wixstatic.com/media/03ccfc_a04d72ddc2b1431190fafef6eb5d466e~mv2.jpg/v1/fill/w_1521,h_919,al_c,q_85/03ccfc_a04d72ddc2b1431190fafef6eb5d466e~mv2.webp",
  "https://static.wixstatic.com/media/03ccfc_699b5fc12e79446598b63cb3457b1722~mv2.jpg/v1/fill/w_1521,h_924,al_c,q_85/03ccfc_699b5fc12e79446598b63cb3457b1722~mv2.webp",
  "https://static.wixstatic.com/media/03ccfc_0c5892f03551446bb2251eb00e456732~mv2.jpg/v1/fill/w_1521,h_922,al_c,q_85/03ccfc_0c5892f03551446bb2251eb00e456732~mv2.webp",
];

const Album = lazy(() => {
  return new Promise<any>((resolve) => {
    setTimeout(() => resolve(import("../../../components/Aulbum")), 300);
  });
});

const ThreeD = () => {
  const [projects, setProjects] = useSessionStorage("3d", []);
  useEffect(() => {
    const fetchData = async () => {
      const { items } = await projectAPI.fetch({
        filter: {
          tags: {
            contains: "3d",
          },
        },
      });
      let target = [items[0]];
      for (let i = 1; i < items.length; i++) {
        const element = items[i];
        if (element.createdAt < items[0].createdAt)
          target = [element, ...target];
        else target.push(element);
      }
      setProjects(target);
    };
    if (projects.length < 1) fetchData();
  });
  return (
    <>
      <div className="portfolio-cover">
        <div
          className="portfolio-background"
          style={{ backgroundImage: `url(${projectsAsset.threed})` }}
        />
        <h1>3D Artworks</h1>
      </div>
      <div className="portfolio-content-body">
        {/* <div className="project-entity-board" style={{ display: "flex" }}>
          <div className="project-file-list" style={{ width: "50%" }}>
            <img alt="3d-preview" src={images[0]} width="100%" />
            <img alt="3d-preview" src={images[1]} width="100%" />
          </div>
          <img alt="3d-preview" src={images[2]} width="50%" />
        </div> */}
        <div className="project-entity-board">
          <div className="project-file-list">
            <img alt="3d-preview" src={images[2]} width="100%" />
            <img alt="3d-preview" src={images[3]} width="100%" />
            {/* <img alt="3d-preview" src={images[4]} width="100%" />
            <img alt="3d-preview" src={images[5]} width="100%" />
            <img alt="3d-preview" src={images[6]} width="100%" /> */}
          </div>
        </div>

        {projects?.map((project: Project, i: number) => (
          <Suspense
            fallback={
              <div>
                <Dimmer active>
                  <Loader></Loader>
                </Dimmer>
                <div
                  style={{
                    width: "100%",
                    height: "10rem",
                    display: "flex",
                    overflow: "hidden",
                  }}
                />
              </div>
            }
            key={`key-project-item-${i}`}
          >
            <Album project={project} />
          </Suspense>
        ))}
      </div>
    </>
  );
};

export default ThreeD;

import { Reorder } from "framer-motion";
import { forwardRef, useImperativeHandle, useState } from "react";
import { Button } from "semantic-ui-react";
import { bytesToMegaBytes, generator, imageAPI, processArtWorks, videoAPI } from "../../utils";
import Dropbox from "../Dropbox";
import "./styles.css";

interface RefType {
  handleSubmit: () => void;
}
interface Props {
  disableSubmit?: true;
  ref?: RefType;
}

export const ImageUpload = forwardRef<RefType, Props>(
  ({ disableSubmit }, ref) => {
    const [files, setFiles] = useState<File[]>([]);
    const handleCancel = () => setFiles([]);
    const handleSubmit = async (project?: string) => {
      if (files.length > 0) {
        const { inputs, image4K, imageHD } = await processArtWorks(
          files,
          project
        );
        const results = await imageAPI.create(files, image4K, imageHD, inputs);
        console.log(results);
        handleCancel();
        return inputs.map((item) => item.uri);
      }
      return [];
    };
    useImperativeHandle(ref, () => ({ handleSubmit }));
    return (
      <div>
        <Reorder.Group
          className="file-list-reorder"
          values={files}
          onReorder={setFiles}
        >
          {files.map((file: File, idx) => (
            <Reorder.Item
              className="file-row-item"
              key={`file-row-item-${file.name}`}
              value={file}
            >
              <span>{file.name}</span>
              <span>{bytesToMegaBytes(file.size).toFixed(2)}MB</span>
              <span>{file.type}</span>
              <span>{new Date(file.lastModified).toDateString()}</span>
              <Button
                className="row-item-delete"
                onClick={() => {
                  files.splice(idx, 1);
                  setFiles([...files]);
                }}
              >
                Delete
              </Button>
            </Reorder.Item>
          ))}
        </Reorder.Group>
        <Dropbox
          handleFiles={setFiles}
          style={{ height: "10rem", width: "100%", padding: "2rem" }}
        >
          <h3>{"Drag & Drop Images Here or Click to Browse"}</h3>
        </Dropbox>
        {!disableSubmit && (
          <div className="upload-img-panel">
            <Button.Group>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button.Or text="or" />
              <Button positive onClick={() => handleSubmit()}>
                Upload
              </Button>
            </Button.Group>
          </div>
        )}
      </div>
    );
  }
);

export const ModelUpload = forwardRef<RefType, Props>(
  ({ disableSubmit }, ref) => {
    const [files, setFiles] = useState<File[]>([]);
    const handleCancel = () => setFiles([]);
    const handleSubmit = async (project?: string) => {
      if (files.length > 0) {
        const { inputs, image4K, imageHD } = await processArtWorks(
          files,
          project
        );
        const results = await imageAPI.create(files, image4K, imageHD, inputs);
        console.log(results);
        return inputs.map((item) => item.uri);
      }
      return [];
    };
    useImperativeHandle(ref, () => ({ handleSubmit }));
    return (
      <div>
        <h3>Upload Images</h3>
        <Dropbox
          handleFiles={setFiles}
          style={{ height: "10rem", width: "100%", padding: "2rem" }}
        >
          <h3>{"Drag & Drop Images Here or Click to Browse"}</h3>
        </Dropbox>
        {!disableSubmit && (
          <div className="upload-img-panel">
            <Button.Group>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button.Or text="or" />
              <Button positive onClick={() => handleSubmit()}>
                Upload
              </Button>
            </Button.Group>
          </div>
        )}
      </div>
    );
  }
);

export const VideoUpload = forwardRef<RefType, Props>(
  ({ disableSubmit }, ref) => {
    const [files, setFiles] = useState<File[]>([]);
    const handleCancel = () => setFiles([]);
    const handleSubmit = async (project?: string) => {
      if (files.length > 0) {
        const projects = project ? [project] : [];
        const inputs = files.map((file) => {
          return {
            id: generator.genGuid(),
            name: file.name,
            info: JSON.stringify({ size: file.size }),
            type: file.type,
            uri: file.name.toLowerCase().replace(" ", "_"),
            projects,
          };
        });
        const results = await videoAPI.create(inputs, files);
        console.log(results);
        return inputs.map((item) => item.uri);
      }
      return [];
    };
    useImperativeHandle(ref, () => ({ handleSubmit }));
    return (
      <div>
        <Dropbox
          handleFiles={setFiles}
          style={{ height: "10rem", width: "100%", padding: "2rem" }}
        >
          <h3>{"Drag & Drop Videos Here or Click to Browse"}</h3>
        </Dropbox>
        {!disableSubmit && (
          <div className="upload-img-panel">
            <Button.Group>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button.Or text="or" />
              <Button positive onClick={() => handleSubmit()}>
                Upload
              </Button>
            </Button.Group>
          </div>
        )}
      </div>
    );
  }
);

export const LinkUpload = forwardRef<RefType, Props>(() => {
  return (
    <div>
      Link List
      <div></div>
    </div>
  );
});

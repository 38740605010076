import { motion } from "framer-motion";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Menu, Visibility } from "semantic-ui-react";
import images from "../../assets/images";
import "./styles.css";

export const NavItem = ({ to = "/", ...props }) => {
  return (
    <Menu.Item {...props}>
      <NavLink to={to}>{props.children}</NavLink>
    </Menu.Item>
  );
};

const menuStyle = {
  border: "none",
  borderRadius: 0,
  boxShadow: "none",
  height: "4rem",
  width: "100vw",
  backgroundColor: "transparent",
  transition: "box-shadow 0.5s ease, padding 0.5s ease",
};

const fixedMenuStyle = {
  opacity: 0.9,
  height: "4rem",
  boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
};

const Navigation = ({ ...props }) => {
  const [menuFixed, setMenuFixed] = useState(false);
  return (
    <Visibility
      onBottomPassed={() => setMenuFixed(true)}
      onBottomVisible={() => setMenuFixed(false)}
      once={false}
    >
      <Menu
        inverted
        borderless
        className="navigation"
        fixed={menuFixed ? "top" : undefined}
        style={menuFixed ? fixedMenuStyle : menuStyle}
      >
        <NavItem position="left" header>
          <div className="nav-header">
            <motion.div
              className="nav-banner"
              animate={{ y: [0, -3, 0, 3, 0] }}
              transition={{ duration: 2, repeat: Infinity, repeatType: "loop" }}
            >
              <img className="logo-img ui avatar image" src={images.logo} alt="logo" />
            </motion.div>
            Home
          </div>
        </NavItem>
        {props.children}
      </Menu>
    </Visibility>
  );
};

export default Navigation;

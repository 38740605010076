import { API, graphqlOperation, Storage } from "aws-amplify";
import {
  Contact,
  Tag,
  File as FileType,
  Project,
  CreateProjectInput,
  UpdateProjectInput,
  CreateContactInput,
  UpdateFileInput,
  CreateFileInput,
  CreateTagInput,
  UpdateTagInput,
} from "../API";
import {
  createContact,
  createFile,
  createProject,
  createTag,
  deleteContact,
  deleteFile,
  deleteProject,
  deleteTag,
  updateFile,
  updateProject,
  updateTag,
} from "../graphql/mutations";
import {
  listContacts,
  listFiles,
  listProjects,
  listTags,
} from "../graphql/queries";

export const build_string = (strList: string[]) => strList.join("/");
export const FILE_TYPE = { image: "image", model: "model", video: "video" };
export const IMAGE_TAG = { full: "FULL", image4K: "4K", imageHD: "HD" };

export const tagAPI = {
  create: async (data: CreateTagInput) => {
    console.log(data);
    return await API.graphql({
      query: createTag,
      variables: { input: data },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  },
  fetch: async (variables = {}) => {
    const { data } = (await API.graphql(
      graphqlOperation(listTags, variables)
    )) as { data: { listTags: { items: Tag[]; nextToken: any } } };
    return data.listTags;
  },
  update: async (input: UpdateTagInput) => {
    return await API.graphql({
      query: updateTag,
      variables: { input },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  },
  delete: async (id: string) => {
    return await API.graphql({
      query: deleteTag,
      variables: { input: { id } },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  },
};

export const contactAPI = {
  create: async (input: CreateContactInput) => {
    return await API.graphql({
      query: createContact,
      variables: { input },
    });
  },
  fetch: async (variables = {}) => {
    const { data } = (await API.graphql(
      graphqlOperation(listContacts, variables)
    )) as { data: { listContacts: { items: Contact[]; nextToken: any } } };
    return data.listContacts;
  },
  delete: async (id: string) => {
    return await API.graphql({
      query: deleteContact,
      variables: { input: { id } },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  },
};

export const projectAPI = {
  create: async (input: CreateProjectInput) => {
    console.log(input);
    return await API.graphql({
      query: createProject,
      variables: { input },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  },
  fetch: async (variables = {}) => {
    const { data } = (await API.graphql(
      graphqlOperation(listProjects, variables)
    )) as { data: { listProjects: { items: Project[]; nextToken: any } } };
    return data.listProjects;
  },
  update: async (input: UpdateProjectInput) => {
    return await API.graphql({
      query: updateProject,
      variables: { input },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  },
  delete: async (id: string) => {
    (
      await tagAPI.fetch({
        filter: {
          projects: { contains: id },
        },
      })
    ).items.forEach((tag) => {
      tag.projects.splice(tag.projects.indexOf(id), 1);
      tagAPI.update({ id: tag.id, projects: tag.projects });
    });
    (
      await fileAPI.fetch({
        filter: {
          projects: { contains: id },
        },
      })
    ).items.forEach((file) => {
      file.projects.splice(file.projects.indexOf(id), 1);
      fileAPI.update({ id: file.id, projects: file.projects });
    });
    return await API.graphql({
      query: deleteProject,
      variables: { input: { id } },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  },
};

export const fileAPI = {
  create: async (
    inputs: CreateFileInput[],
    files: File[],
    callbacks = {
      completeCallback: (event: any) => {
        console.log(`Successfully uploaded ${event.key}`);
      },
      progressCallback: (progress: any) => {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      },
      errorCallback: (err: any) => {
        console.error("Unexpected error while uploading", err);
      },
    }
  ) => {
    //
  },
  fetch: async (variables = {}) => {
    const { data } = (await API.graphql(
      graphqlOperation(listFiles, variables)
    )) as { data: { listFiles: { items: FileType[]; nextToken: any } } };
    return data.listFiles;
  },
  update: async (input: UpdateFileInput) => {
    return await API.graphql({
      query: updateFile,
      variables: { input },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  },
  get: async (uri: string) => {
    return await Storage.get(uri);
  },
};

export const imageAPI = {
  create: async (
    files: File[],
    image4K: File[],
    imageHD: File[],
    inputs: CreateFileInput[],
    callbacks = {
      completeCallback: (event: any) => {
        console.log(`Successfully uploaded ${event.key}`);
      },
      progressCallback: (progress: any) => {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      },
      errorCallback: (err: any) => {
        console.error("Unexpected error while uploading", err);
      },
    }
  ) => {
    return inputs.map(async (input, i) => {
      await Storage.put(
        build_string([FILE_TYPE.image, IMAGE_TAG.full, input.uri]),
        files[i],
        {
          level: "public",
          contentType: input.type,
          ...callbacks,
        }
      );
      await Storage.put(
        build_string([FILE_TYPE.image, IMAGE_TAG.image4K, input.uri]),
        image4K[i],
        {
          level: "public",
          contentType: input.type,
          ...callbacks,
        }
      );
      await Storage.put(
        build_string([FILE_TYPE.image, IMAGE_TAG.imageHD, input.uri]),
        imageHD[i],
        {
          level: "public",
          contentType: input.type,
          ...callbacks,
        }
      );
      return await API.graphql({
        query: createFile,
        variables: { input },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    });
  },
  fetch: async (variables = {}) => {
    const { data } = (await API.graphql(
      graphqlOperation(listFiles, variables)
    )) as { data: { listFiles: { items: FileType[]; nextToken: any } } };
    return data.listFiles;
  },
  listStorage: async (folder = IMAGE_TAG.imageHD) => {
    return await Storage.list(build_string([FILE_TYPE.image, folder]));
  },
  get: async (uri: string, tag = IMAGE_TAG.imageHD) => {
    return await Storage.get(build_string([FILE_TYPE.image, tag, uri]));
  },
  update: async (input: UpdateFileInput) => {
    return await API.graphql({
      query: updateFile,
      variables: { input },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  },
  delete: async (id: string, uri: string) => {
    await Storage.remove(uri);
    await Storage.remove(uri + IMAGE_TAG.image4K);
    await Storage.remove(uri + IMAGE_TAG.imageHD);
    return await API.graphql({
      query: deleteFile,
      variables: { input: { id } },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  },
};
export const videoAPI = {
  create: async (
    inputs: CreateFileInput[],
    files: File[],
    callbacks = {
      completeCallback: (event: any) => {
        console.log(`Successfully uploaded ${event.key}`);
      },
      progressCallback: (progress: any) => {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      },
      errorCallback: (err: any) => {
        console.error("Unexpected error while uploading", err);
      },
    }
  ) => {
    return inputs.map(async (input, i) => {
      await Storage.put(build_string([FILE_TYPE.video, input.uri]), files[i], {
        level: "public",
        contentType: input.type,
        ...callbacks,
      });
      return await API.graphql({
        query: createFile,
        variables: { input },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    });
  },
  get: async (uri: string) => {
    return await Storage.get(build_string([FILE_TYPE.video, uri]));
  },
};

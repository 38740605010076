import { AnimatePresence, motion } from "framer-motion";
import { wrap } from "popmotion";
import { FC, useEffect, useState } from "react";
import "./styles.css";

const variants = {
  enter: () => {
    return {
      opacity: 1,
      transition: { duration: 1 },
    };
  },
  center: () => {
    return {
      zIndex: 1,
      x: -10,
      transition: { duration: 9 },
    };
  },
  exit: () => {
    return {
      zIndex: 0,
      opacity: 0,
    };
  },
};
const swipeConfidenceThreshold = 5000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

export const ImageSlides: FC<{ images: string[] }> = ({ images, ...props }) => {
  const [[page, direction], setPage] = useState([0, 0]);
  const imageIndex = wrap(0, images.length, page);
  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };
  useEffect(() => {
    const interval = setInterval(() => paginate(1), 5000);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  return (
    <>
      <div className="slides-container">
        <AnimatePresence initial={true} custom={direction}>
          <motion.img
            key={page}
            src={images[imageIndex]}
            className="slide-image"
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
            {...props}
          />
        </AnimatePresence>
      </div>
      <div className="prev" onClick={() => paginate(-1)} />
      <div className="next" onClick={() => paginate(1)} />
    </>
  );
};

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dimmer, Icon, Loader } from "semantic-ui-react";
import { imageAPI, IMAGE_TAG, useSessionStorage } from "../../utils";
import { layout } from "../Portfolio/pages/Layout";
import "./styles.css";

const Artwork = () => {
  const { id } = useParams();
  const [url, setUrl] = useSessionStorage(`${id}`, "");
  const [zoom, setZoom] = useState(1);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        if (id.split("-")[0] === "l") {
          setUrl(layout[Number.parseInt(id.split("-")[1])].uri);
        } else setUrl(await imageAPI.get(id, IMAGE_TAG.full));
      }
    };
    if (url === "") fetchData();
  });

  return (
    <>
      <div className="artwork-toolbar">
        <div className="artwork-backward" onClick={() => navigate(-1)}>
          <Icon name="reply" />
        </div>
        <div
          className="artwork-backward"
          onClick={() => {
            if (zoom < 5) setZoom(zoom * 1.2);
            else setZoom(1);
          }}
        >
          <Icon name="zoom in" />
        </div>
        <div
          className="artwork-backward"
          onClick={() => {
            const ratio = zoom * 0.7;
            if (zoom > 1) setZoom(ratio);
            else setZoom(1);
          }}
        >
          <Icon name="zoom out" />
        </div>
      </div>
      <div className="artWork">
        {url !== "" ? (
          <img src={url} alt={id} style={{ width: `calc(100% * ${zoom})` }} />
        ) : (
          <Dimmer active>
            <Loader size="massive">Loading...</Loader>
          </Dimmer>
        )}
      </div>
    </>
  );
};

export default Artwork;
